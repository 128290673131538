<template>
  <div>

    <!-- #region::Searcher, filters and actions buttons -->
    <SuppliersListFilters
      :title="title"
      :without-supplier-term="withoutSupplierTerm"
      :show-supplier-components="showSupplierComponents"
      @on:filter="handleFilter"
      @on:column-option-change="handleColumnOptionChange"
      @on:close-tab="$emit('on:close-tab', title)"
    />
    <!-- #endregion::Searcher, filters and actions buttons -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingProducts">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Products list table -->
          <vue-good-table
            v-if="availableProducts"
            id="reports-table"
            ref="suppliers-products-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="formatedProducts"
            :group-options="{
              enabled: canShowPricesHeaders,
              headerPosition: 'top'
            }"
            :sort-options="{
              enabled: false,
            }"
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              This will show up when there are no rows
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Table header row -->
            <template
              slot="table-header-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field === 'totalCost'"
                class="yellow-row"
              >
                {{ totalCost | currency }}
              </div>
              <div
                v-else-if="props.column.field === 'totalSale'"
                class="yellow-row"
              >
                {{ totalSale | currency }}
              </div>
              <div
                v-else
                id="empty-header"
                class="border-none"
              />
            </template>
            <!-- #endregion::Table header row -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd text-left">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="align-self-s"><strong>{{ props.column.label }}</strong></div>
                    <div v-if="columnTooltip(props.column.field)">
                      <b-button
                        v-b-tooltip.hover.right="columnTooltip(props.column.field)"
                        variant="flat-secondary"
                        size="sm"
                        class="btn-icon rounded-circle ml-25 p-0"
                      >
                        <feather-icon icon="AlertCircleIcon" />
                      </b-button>
                    </div>
                  </div>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- #region::Subcategory cell -->
              <div
                v-if="props.column.field === 'name_category'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.name_category }}
                </h6>
              </div>
              <!-- #endregion::Subcategory cell -->

              <!-- #region::Code cell -->
              <div
                v-else-if="props.column.field === 'Code'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Code }}
                </h6>
              </div>
              <!-- #endregion::Code cell -->

              <!-- #region::Item cell -->
              <div
                v-else-if="props.column.field === 'Item'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Item }}
                </h6>
              </div>
              <!-- #endregion::Item cell -->

              <!-- #region::Name cell -->
              <div
                v-else-if="props.column.field === 'Name'"
                class="mt-2"
              >
                <router-link
                  :to="{ name: 'product-details', params: { id: props.row.IdProduct } }"
                  target="_blank"
                ><span
                  class="text-nowrap"
                  style="font-size: 14px;"
                >{{ props.row.Name }}</span>
                </router-link>
              </div>
              <!-- #endregion::Name cell -->

              <!-- #region::Brand cell -->
              <div
                v-else-if="props.column.field === 'name_brand'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.name_brand }}
                </h6>
              </div>
              <!-- #endregion::Brand cell -->

              <!-- #region::Supplier cell -->
              <div
                v-else-if="props.column.field === 'NameSupplier'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.NameSupplier || 'Sin proveedor' }}
                </h6>
              </div>
              <!-- #endregion::Supplier cell -->

              <!-- #region::Image cell -->
              <div v-else-if="props.column.field === 'image'">
                <b-img
                  height="60"
                  :src="props.row.images.small[0] && props.row.images.small[0].image"
                />
              </div>
              <!-- #endregion::Image cell -->

              <!-- #region::Total invenory cell -->
              <div
                v-else-if="props.column.field === 'totalInventory'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalInventory }}</h6>
              </div>
              <!-- #endregion::Total invenory cell -->

              <!-- #region::Total GDL cell -->
              <div
                v-else-if="props.column.field === 'totalGDL'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalGDL }}</h6>
              </div>
              <!-- #endregion::Total GDL cell -->

              <!-- #region::Total CDMX cell -->
              <div
                v-else-if="props.column.field === 'totalCDMX'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalCDMX }}</h6>
              </div>
              <!-- #endregion::Total CDMX cell -->

              <!-- #region::Total store cell -->
              <div
                v-else-if="props.column.field === 'totalStore'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalStore }}</h6>
              </div>
              <!-- #endregion::Total store cell -->

              <!-- #region::Total on way cell -->
              <div
                v-else-if="props.column.field === 'totalOnWay'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalOnWay }}</h6>
              </div>
              <!-- #endregion::Total on way cell -->

              <!-- #region::Total presale cell -->
              <div
                v-else-if="props.column.field === 'totalPresale'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalPresale }}</h6>
              </div>
              <!-- #endregion::Total presale cell -->

              <!-- #region::Total sold presale cell -->
              <div
                v-else-if="props.column.field === 'totalPiecesSoldPresale'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalPiecesSoldPresale }}</h6>
              </div>
              <!-- #endregion::Total sold presale cell -->

              <!-- #region::Total in order cell -->
              <div
                v-else-if="props.column.field === 'totalInOrder'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalInOrder }}</h6>
              </div>
              <!-- #endregion::Total in order cell -->

              <!-- #region::Total sold cell -->
              <div
                v-else-if="props.column.field === 'totalSold'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalSold }}</h6>
              </div>
              <!-- #endregion::Total sold cell -->

              <!-- #region::Total returned cell -->
              <div
                v-else-if="props.column.field === 'totalReturned'"
                class="mt-2 text-center"
              >
                <h6>{{ props.row.totalReturned }}</h6>
              </div>
              <!-- #endregion::Total returned cell -->

              <!-- #region::Total min price cell -->
              <div
                v-else-if="props.column.field === 'PriceMin'"
                class="mt-2"
              >
                <h6>{{ validateCurrency(props.row.PriceMin) }}</h6>
              </div>
              <!-- #endregion::Total min price cell -->

              <!-- #region::Total sale cell -->
              <div
                v-else-if="props.column.field === 'totalSale'"
                class="mt-2"
              >
                <h6>{{ validateCurrency(props.row.totalSale) }}</h6>
              </div>
              <!-- #endregion::Total sale cell -->

              <!-- #region::Total provider price cell -->
              <div
                v-else-if="props.column.field === 'ProvidersPriceUsd'"
                class="mt-2"
              >
                <h6>{{ validateCurrency(props.row.ProvidersPriceUsd) }}</h6>
              </div>
              <!-- #endregion::Total provider price cell -->

              <!-- #region::Total cost cell -->
              <div
                v-else-if="props.column.field === 'totalCost'"
                class="mt-2"
              >
                <h6>{{ validateCurrency(props.row.totalCost) }}</h6>
              </div>
              <!-- #endregion::Total cost cell -->

            </template>
          </vue-good-table>
          <!-- #endregion::Products list table -->

          <!-- #region::Alert when no transfers are available -->
          <b-alert
            v-if="!availableProducts && !isLoadingProducts"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron productos.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no transfers are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableProducts"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalProducts"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BSkeleton, BCard, BCardText, BImg, BAlert, VBTooltip, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import SuppliersListFilters from '@/modules/reports/suppliers/components/SuppliersListFilters.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

import suppliersListTooltips from '@/helpers/SuppliersListTooltips'
// #endregion

export default {
  components: {
    BImg,
    BCard,
    BAlert,
    BButton,
    BSkeleton,
    BCardText,
    VueGoodTable,
    BasicPaginator,
    SuppliersListFilters,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     * Titulo que se muestra en la pestaña
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Indica si se deben cargar los componentes relacionados con los proveedores
     */
    showSupplierComponents: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se deben cargar los productos sin proveedor
     */
    withoutSupplierTerm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      lastPage: 0,
      totalProducts: 0,
      columns: [
        {
          label: 'Subcategoría',
          field: 'name_category',
          hidden: !this.$ability.can('read subcategory', 'Reports'),
        },
        {
          label: 'Código interno',
          field: 'Code',
          hidden: !this.$ability.can('read code', 'Reports'),
        },
        {
          label: 'ITEM',
          field: 'Item',
          hidden: !this.$ability.can('read item', 'Reports'),
        },
        {
          label: 'Producto',
          field: 'Name',
          hidden: !this.$ability.can('read name', 'Reports'),
        },
        {
          label: 'Marca',
          field: 'name_brand',
          hidden: !this.$ability.can('read brand', 'Reports'),
        },
        {
          label: 'Proveedor',
          field: 'NameSupplier',
          hidden: !this.$ability.can('read supplier', 'Reports'),
        },
        {
          label: 'Imagen',
          field: 'image',
          hidden: !this.$ability.can('read image', 'Reports'),
        },
        {
          label: 'Existencia equipos',
          field: 'totalInventory',
          type: 'number',
          tooltip: 'A simple tooltip',
          tdClass: this.rowStyle,
          hidden: !this.$ability.can('read total', 'Reports'),
        },
        {
          label: 'GDL',
          field: 'totalGDL',
          type: 'number',
          hidden: !this.$ability.can('read gdl', 'Reports'),
        },
        {
          label: 'CDMX',
          field: 'totalCDMX',
          type: 'number',
          hidden: !this.$ability.can('read cdmx', 'Reports'),
        },
        {
          label: 'Tienda',
          field: 'totalStore',
          type: 'number',
          hidden: !this.$ability.can('read store', 'Reports'),
        },
        {
          label: '',
          field: 'divider',
          width: '12px !important',
          tdClass: 'divider-cell',
          thClass: 'divider-header',
          hidden: this.areAllColumnsHidden,
        },
        {
          label: 'Total en camino',
          field: 'totalOnWay',
          type: 'number',
          tdClass: 'blue-light-cell',
          hidden: !this.$ability.can('read onway', 'Reports'),
        },
        {
          label: 'En camino disponibles',
          field: 'totalPresale',
          type: 'number',
          hidden: !this.$ability.can('read presale', 'Reports'),
        },
        {
          label: 'Vendido preventa',
          field: 'totalPiecesSoldPresale',
          type: 'number',
          hidden: !this.$ability.can('read sale_presale', 'Reports'),
        },
        {
          label: 'Equipos en órdenes',
          field: 'totalInOrder',
          type: 'number',
          hidden: !this.$ability.can('read order', 'Reports'),
        },
        {
          label: 'Total vendidos',
          field: 'totalSold',
          type: 'number',
          hidden: !this.$ability.can('read sold', 'Reports'),
        },
        {
          label: 'Devueltas',
          field: 'totalReturned',
          type: 'number',
          hidden: !this.$ability.can('read returned', 'Reports'),
        },
        {
          label: 'Precio de venta unitario',
          field: 'PriceMin',
          hidden: !this.$ability.can('read min_price', 'Reports'),
        },
        {
          label: 'Precio venta total',
          field: 'totalSale',
          hidden: !this.$ability.can('read total_sold', 'Reports'),
        },
        {
          label: 'Costo proveedor unitario',
          field: 'ProvidersPriceUsd',
          hidden: !this.$ability.can('read supplier_cost', 'Reports'),
        },
        {
          label: 'Costo total',
          field: 'totalCost',
          hidden: !this.$ability.can('read total_cost', 'Reports'),
        },
      ],
      products: [],
      isLoadingProducts: false,
      totalSale: 0,
      totalCost: 0,
      areAllColumnsHidden: false,
      tooltipsTexts: suppliersListTooltips,
    }
  },
  computed: {
    ...mapGetters({
      getListsParams: 'reportsSuppliers/getListsParams',
      getColumnsList: 'reportsSuppliers/getColumnsList',
      /**
       * Filtros globales
       */
      getFilteredBrand: 'filters/getFilteredBrand',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSupplier: 'filters/getFilteredSupplier',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredSubcategory: 'filters/getFilteredSubcategory',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
     * Lista de columnas
     */
    columnsList: {
      get() { return this.getColumnsList },
    },
    /**
     * Marca filtrada (filtro en store)
     */
    filteredBrand: {
      get() { return this.getFilteredBrand },
      set(value) { this.setFilteredBrand(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Proveedor filtrado (filtro en store)
     */
    filteredSupplier: {
      get() { return this.getFilteredSupplier },
      set(value) { this.setFilteredSupplier(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Subcategoría filtrada (filtro en store)
     */
    filteredSubcategory: {
      get() { return this.getFilteredSubcategory },
      set(value) { this.setFilteredSubcategory(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    availableProducts() {
      return this.totalProducts > 0
    },
    canShowPricesHeaders() {
      return this.$ability.can('read total_sold', 'Reports')
        || this.$ability.can('read total_cost', 'Reports')
    },
    formatedProducts() {
      if (this.canShowPricesHeaders) {
        return [{
          label: 'Prcios',
          html: false,
          children: this.products,
        }]
      }

      return this.products
    },
    isLoadedParamsForList() {
      return this.listsParams.findIndex(params => params.title === this.title) !== -1
    },
  },

  /**
   * Hook que se ejecuta cuando el componente es montado
   *
   * @summary Si la lista ya ha sido cargada previamente, se cargan las columnas
   * que han sido seleccionadas desactivadas
   */
  mounted() {
    if (this.isLoadedParamsForList) {
      const { selectedColumns } = this.getListsParams.find(p => p.title === this.title)
      const hiddenColumns = selectedColumns.filter(c => c.active === false)

      hiddenColumns.forEach(hiddenColumn => {
        this.handleColumnOptionChange(hiddenColumn)
      })
    }
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Carga la lista de traspasos
  */
  async created() {
    await this.loadProductsListWithVerification()
  },
  methods: {
    ...mapActions({
      loadProducts: 'reportsSuppliers/loadProducts',

      /**
       * Filtros globales
       */
      setFilteredBrand: 'filters/setFilteredBrand',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSupplier: 'filters/setFilteredSupplier',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredSubcategory: 'filters/setFilteredSubcategory',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
    }),
    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de productos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'brand': this.filteredBrand = filter.value
          break
        case 'category': this.filteredSubcategory = filter.value
          break
        case 'supplier': this.filteredSupplier = filter.value
          break
        default: this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadProductsListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadProductsListWithVerification() {
      await this.loadProductsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadProductsList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.products.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableProducts) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadProductsList()
        }
      }
    },

    /**
    * Carga de registros de tipo productos
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de productos y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de traspasos⁡
    */
    async loadProductsList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingProducts = true

        const response = await this.loadProducts(this.loadParams())
        this.products = response.data.data.products.data
        this.totalProducts = response.data.data.products.total
        this.lastPage = response.data.data.products.last_page

        this.totalSale = response.data.data.sum_total_sale
        this.totalCost = response.data.data.sum_total_cost

        this.isLoadingProducts = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista. Verifica si la lista ya ha sido
    * previamente con parametros para la selección de columnas. Si no ha sido cargada previamente,
    * se cargan todas las columnas desde el store. En caso contrario, se cargan las columnas
    * relacionadas con la lista
    */
    loadParams() {
      console.log(this.isLoadedParamsForList)
      let supplierTerm = ''

      if (this.title === 'Todos') {
        supplierTerm = this.filteredSupplier
      } else {
        supplierTerm = (!this.withoutSupplierTerm && !this.showSupplierComponents) ? this.title : ''
      }

      return {
        title: this.title,
        perPage: this.filteredPerPage,
        brandTerm: this.filteredBrand,
        statusTerm: this.filteredStatus,
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
        categoryTerm: this.filteredSubcategory,
        withoutSupplierTerm: this.withoutSupplierTerm ? 1 : 0,

        supplierTerm,

        selectedColumns: this.isLoadedParamsForList
          ? this.getListsParams.find(p => p.title === this.title).selectedColumns
          : this.columnsList.map(a => ({ ...a })),
      }
    },

    /**
    * Estilos de celdas
    * @summary Establece los estilos de las celdas de la tabla. Amariilo si el inventario es
    * mayor a 0, rojo si es menor o igual a 0
    * @param {Object} row - Objeto de la fila
    */
    rowStyle(row) {
      if (row?.totalInventory > 0) {
        return 'yellow-cell'
      }

      return 'red-cell'
    },

    /**
    * Validador de totales
    *
    * @summary Valida que el valor sea nulo, en caso de serlo devuelve N/A, en caso contrario
    * devuelve el valor formateado como moneda
    * @param {Number} value - Valor a validar
    */
    validateCurrency(value) {
      if (value === null) {
        return 'N/A'
      }

      const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      return USDollar.format(value)
    },

    /**
    * Gestionador de cambio de opción de columna
    *
    * @summary Gestiona el cambio de opción de columna. Oculta o muestra la columna
    * @param {Object} column - Objeto de la columna
    */
    handleColumnOptionChange(column) {
      const listColumn = this.columns.find(c => c.field === column.value)
      this.$set(listColumn, 'hidden', !column.active)
      this.checkDividerColumn()
    },

    /**
    * Evento de linea divisora
    *
    * @summary Revisa si se debe ocultar la linea divisora en base a las columnas
    * visibles en la tabla
    */
    checkDividerColumn() {
      const loadedColumns = this.columns.filter(c => c.field !== 'divider' && c.hidden === false)
      const dividerColumn = this.columns.find(c => c.field === 'divider')
      this.$set(dividerColumn, 'hidden', loadedColumns.length === 0)
    },

    columnTooltip(field) {
      const column = this.tooltipsTexts.find(c => c.field === field)
      return column?.text
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
  .vgt-table td.divider-cell {
    background-color: #6B6B6B !important;
    border-left: 1px solid #DCDFE6;
    border-bottom: none;
    border-top: 0px solid #DCDFE6;
    padding: 0px !important;
  }

  .vgt-table tbody th.divider-cell {
    background-color: #6B6B6B !important;
    border-left: 1px solid #DCDFE6;
    border-bottom: none;
    border-top: none;
    padding: 0px !important;
  }

  .vgt-table thead th.divider-header {
    padding: 0px !important;
    background-color: #6B6B6B !important;
    border-left: 1px solid #DCDFE6;
    border-right: 1px solid #DCDFE6;
    border-bottom: none;
  }
</style>
