<template>
  <b-card>
    <b-card-text>
      <b-row>
        <b-col md="12">
          <h4>Proveedor</h4>
        </b-col>

        <b-col md="12">
          <v-select
            v-model="selectedSuppliers"
            multiple
            label="title"
            placeholder="Selecciona un proveedor"
            :options="suppliers"
            :clearable="false"
            @option:deselected="$emit('option:deselected', $event.title)"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    vSelect,
  },
  computed: {
    ...mapGetters({
      getSuppliers: 'suppliers/getSuppliers',
      getSelectedSuppliers: 'reportsSuppliers/getSelectedSuppliers',
    }),
    /**
     * Proveedores seleccionados
     */
    selectedSuppliers: {
      get() { return this.getSelectedSuppliers },
      set(value) { this.setSelectedSuppliers(value) },
    },
    suppliers() {
      return this.getSuppliers.map(supplier => ({
        title: supplier.Name,
        id: supplier.IdSupplier,
      }))
    },
  },
  watch: {
    selectedSuppliers(newSuppliers, oldSuppliers) {
      // eslint-disable-next-line no-extend-native, func-names
      Array.prototype.diff = function (a) {
        return this.filter(i => a.indexOf(i) < 0)
      }

      const newElements = newSuppliers.diff(oldSuppliers)

      if (newElements.length > 0) {
        const newTab = {
          title: newElements[0].title,
          value: newElements[0].id,
        }

        this.addTab(newTab)
        this.$emit('option:selected', newElements[0].title)
      }
    },
  },
  methods: {
    ...mapActions({
      addTab: 'reportsSuppliers/addTab',
      setSelectedSuppliers: 'reportsSuppliers/setSelectedSuppliers',
    }),
    onSupplierDeselected(value) {
      this.$emit('option:deselected', value.title)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.style-chooser {
  background: #ffffff;
}
</style>
